
import './Home.css';
import { db, firebaseAuth } from '../utils/firebase-config';
import { useEffect, useState } from 'react';
import ModalPerfil from './ModalPerfil';
import imggFilter from "../assets/filters.png";
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { collection, getDocs } from 'firebase/firestore';
function Home() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataB, setDataB] = useState([]);
  const [modal2, setModal2] = useState(false);
  const [idadeFiltro, setidadeFiltro] = useState('');
  const [cacheFiltro, setCacheFiltro] = useState('');
  const [cartao, setCartao] = useState(false);
  const [dinheiro, setDinheiro] = useState(false);
  const [pix, setPix] = useState(false);
  const [alta, setAlta] = useState(false);
  const [lingua, setLingua] = useState(false);
  const [negro, setNegro] = useState(false);
  const [branca, setBranca] = useState(false);
  const [dourada, setDourada] = useState(false);
  const [chuvaNegra, setChuvaNegra] = useState(false);
  const [depilada, setDepilada] = useState(false);
  const [despedida, setDespedida] = useState(false);
  const [domicilio, setDomicilio] = useState(false);
  const [duplas, setDuplas] = useState(false);
  const [ejaCorpo, setEjaCorpo] = useState(false);
  const [facial, setFacial] = useState(false);
  const [fantasias, setFantasias] = useState(false);
  const [festas, setFestas] = useState(false);
  const [fetichismo, setFetichismo] = useState(false);
  const [gargantaProfunda, setGargantaProfunda] = useState(false);
  const [gordinha, setGordinha] = useState(false);
  const [hotel, setHotel] = useState(false);
  const [jantar, setJantar] = useState(false);
  const [latina, setLatina] = useState(false);
  const [ligacao, setLigacao] = useState(false);
  const [loira, setLoira] = useState(false);
  const [magra, setMagra] = useState(false);
  const [massagem, setMassagem] = useState(false);
  const [mignon, setMignon] = useState(false);
  const [morena, setMorena] = useState(false);
  const [mulata, setMulata] = useState(false);
  const [namoradinha, setNamoradinha] = useState(false);
  const [natural, setNatural] = useState(false);
  const [negra, setNegra] = useState(false);
  const [oralAteFinal, setOralAteFinal] = useState(false);
  const [oralCamisinha, setOralCamisinha] = useState(false);
  const [oralSemCamisinha, setOralSemCamisinha] = useState(false);
  const [oriental, setOriental] = useState(false);
  const [peluda, setPeluda] = useState(false);
  const [precoPorHora, setPrecoPorHora] = useState(false);
  const [pse, setPse] = useState(false);
  const [ruiva, setRuiva] = useState(false);
  const [sadoDuro, setSadoDuro] = useState(false);
  const [sadoSuave, setSadoSuave] = useState(false);
  const [sexoAnal, setSexoAnal] = useState(false);
  const [silicone, setSilicone] = useState(false);
  const [squirting, setSquirting] = useState(false);
  const [strapOn, setStrapOn] = useState(false);
  const [swing, setSwing] = useState(false);
  const [viagens, setViagens] = useState(false);
  const [videochamada, setVideochamada] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [logado, setLogado] = useState(false);
  const [clienteNovo, setClienteNovo] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [refIdade, setRefIdade] = useState();

  function filtrarMaster(params) {
    console.log('ALLMASTER');
    var filtrosMasterMaster = dataB;
    var filtrosM = data;
    if (idadeFiltro.length > 0) {
      filtrosM = data.filter(element => {
        return Number(element.idade) <= Number(idadeFiltro)
      })
    }
    if (filtrosM.length > 0) {
      setData(filtrosM);
    }
    console.log('filtrosM ', filtrosM);
    console.log('Data C incio', data);

    setTimeout(() => {
      if (cacheFiltro.length > 1) {
        console.log('cache Filtro', cacheFiltro);
        if (idadeFiltro.length > 0) {
          setData(filtrosM.filter(element => {
            return Number(element.precoPorHora) <= Number(cacheFiltro);
          }))
        } else {
          setData(data.filter(element => {
            return Number(element.precoPorHora) <= Number(cacheFiltro);
          }))
        }
      }
      masterFt();
    }, 1000);

    async function masterFt(params) {
      var filteredBolean = filtrosM;
      console.log('MASTERRRRR ', filtrosM);
      if (whatsapp) {
        console.log('WHATSAPP FILTER')
        function assF(params) {
        }
        filteredBolean = filteredBolean.filter(element => {
          return element.whatsApp == whatsapp
        })
      }
      if (viagens) {
        filteredBolean = filteredBolean.filter(element => {
          return element.viagens == viagens
        })
      }
      if (depilada) {
        filteredBolean = filteredBolean.filter(element => {
          return element.depilada == depilada
        })
      }
      if (cartao) {
        console.log('cartao Filtro');
        filteredBolean = filteredBolean.filter(element => {
          return element.aceitaCredito == cartao
        })
      }
      if (dinheiro) {
        console.log('dinheiro Filtro');
        filteredBolean = filteredBolean.filter(element => {
          return element.aceitaDinheiro == dinheiro
        })
      }
      if (pix) {
        console.log('pix Filtro');
        filteredBolean = filteredBolean.filter(element => {
          return element.aceitaPix == pix
        })
      }
      if (lingua) {
        console.log('lingua Filtro');

        filteredBolean = filteredBolean.filter(element => {
          return element.beijo == lingua
        })
      }
      if (negro) {

        filteredBolean = filteredBolean.filter(element => {
          return element.beijoNegro == negro
        })
      }
      if (branca) {
        filteredBolean = filteredBolean.filter(element => {
          return element.branca == branca
        })
      }
      if (dourada) {

        filteredBolean = filteredBolean.filter(element => {
          return element.chuvaDourada == dourada
        })
      }
      if (chuvaNegra) {
        filteredBolean = filteredBolean.filter(element => {
          return element.chuvaNegra == chuvaNegra
        })
      }
      if (domicilio) {
        filteredBolean = filteredBolean.filter(element => {
          return element.domicilio == domicilio
        })
      }
      if (despedida) {
        filteredBolean = filteredBolean.filter(element => {
          return element.despedidas == despedida
        })
      }
      if (duplas) {
        filteredBolean = filteredBolean.filter(element => {
          return element.duplas == duplas
        })
      }
      if (ejaCorpo) {
        filteredBolean = filteredBolean.filter(element => {
          return element.ejaculacaoCorpo == ejaCorpo
        })
      }
      if (ejaCorpo) {
        filteredBolean = filteredBolean.filter(element => {
          return element.ejaculacaoCorpo == ejaCorpo
        })
      }
      if (facial) {
        filteredBolean = filteredBolean.filter(element => {
          return element.facial == facial
        })
      }
      if (fantasias) {
        filteredBolean = filteredBolean.filter(element => {
          return element.fantasias == fantasias
        })
      }
      if (festas) {
        filteredBolean = filteredBolean.filter(element => {
          return element.festasEventos == festas
        })
      }
      if (fetichismo) {
        filteredBolean = filteredBolean.filter(element => {
          return element.fetichismo == fetichismo
        })
      }
      if (gargantaProfunda) {
        filteredBolean = filteredBolean.filter(element => {
          return element.gargantaProfunda == gargantaProfunda
        })
      }
      if (gordinha) {
        filteredBolean = filteredBolean.filter(element => {
          return element.gordinha == gordinha
        })
      }
      if (hotel) {
        filteredBolean = filteredBolean.filter(element => {
          return element.hotel == hotel
        })
      }
      if (jantar) {
        filteredBolean = filteredBolean.filter(element => {
          return element.jantarRomantico == jantar
        })
      }
      if (latina) {
        filteredBolean = filteredBolean.filter(element => {
          return element.latina == latina
        })
      }
      if (ligacao) {
        filteredBolean = filteredBolean.filter(element => {
          return element.ligacao == ligacao
        })
      }
      if (magra) {
        filteredBolean = filteredBolean.filter(element => {
          return element.magra == magra
        })
      }
      if (loira) {
        filteredBolean = filteredBolean.filter(element => {
          return element.loira == loira
        })
      }
      if (mignon) {
        filteredBolean = filteredBolean.filter(element => {
          return element.mignon == mignon
        })
      }
      if (massagem) {
        filteredBolean = filteredBolean.filter(element => {
          return element.massagem == massagem
        })
      }
      if (morena) {
        filteredBolean = filteredBolean.filter(element => {
          return element.morena == morena
        })
      }
      if (mulata) {
        filteredBolean = filteredBolean.filter(element => {
          return element.mulata == mulata
        })
      }
      if (namoradinha) {
        filteredBolean = filteredBolean.filter(element => {
          return element.namoradinha == namoradinha
        })
      }
      if (natural) {
        filteredBolean = filteredBolean.filter(element => {
          return element.natural == natural
        })
      }
      if (negra) {
        filteredBolean = filteredBolean.filter(element => {
          return element.negra == negra
        })
      }
      if (oralAteFinal) {
        filteredBolean = filteredBolean.filter(element => {
          return element.oralAteFinal == oralAteFinal
        })
      }
      if (oralCamisinha) {
        filteredBolean = filteredBolean.filter(element => {
          return element.oralCamisinha == oralCamisinha
        })
      }
      if (oralSemCamisinha) {
        filteredBolean = filteredBolean.filter(element => {
          return element.oralSemCamisinha == oralSemCamisinha
        })
      }
      if (oriental) {
        filteredBolean = filteredBolean.filter(element => {
          return element.oriental == oriental
        })
      }
      if (peluda) {
        filteredBolean = filteredBolean.filter(element => {
          return element.peluda == peluda
        })
      }
      if (pse) {
        filteredBolean = filteredBolean.filter(element => {
          return element.pse == pse
        })
      }
      if (ruiva) {
        filteredBolean = filteredBolean.filter(element => {
          return element.ruiva == ruiva
        })
      }
      if (sadoDuro) {
        filteredBolean = filteredBolean.filter(element => {
          return element.sadoDuro == sadoDuro
        })
      }
      if (sadoSuave) {
        filteredBolean = filteredBolean.filter(element => {
          return element.sadoSuave == sadoSuave
        })
      }
      if (sexoAnal) {
        filteredBolean = filteredBolean.filter(element => {
          return element.sexoAnal == sexoAnal
        })
      }
      if (silicone) {
        filteredBolean = filteredBolean.filter(element => {
          return element.silicone == silicone
        })
      }
      if (squirting) {
        filteredBolean = filteredBolean.filter(element => {
          return element.squirting == squirting
        })
      }
      if (strapOn) {
        filteredBolean = filteredBolean.filter(element => {
          return element.strapOn == strapOn
        })
      }
      if (swing) {
        filteredBolean = filteredBolean.filter(element => {
          return element.swing == swing
        })
      }
      if (videochamada) {
        filteredBolean = filteredBolean.filter(element => {
          return element.videoChamada == videochamada
        })
      }
      if (alta) {
        console.log('alta Filtro');
        filteredBolean = filteredBolean.filter(element => {
          return element.alta == alta
        })
      }
      setData(filteredBolean);
      setModal2(false);
    }
  }

  function normalizeString(string) {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  
  function setSearch(params) {

    let parlenght = params.length;
    setData(dataB.filter(element => {
        if(params.length < 1){
          return dataB;
        } else {
          return element.cidade.toLowerCase().slice(0, parlenght) == params.toLowerCase().slice(0, parlenght);
        }
  
  
  }))
  }
  
  function setSearchNome(params) {
    let normalizedParams = normalizeString(params.toLowerCase());
    setData(dataB.filter(element => {
      if (normalizedParams.length < 1) {
        return true;
      } else {
        let normalizedName = normalizeString(element.nome.toLowerCase());
        return normalizedName.startsWith(normalizedParams);
      }
    }));
  }

  function setSearchIdade(params) {
    setData(dataB.filter(element => {
      if (params.length < 1) {
        return dataB;
      } else {
        return element.idade.slice(0, params.length) == params.slice(0, params.length);
      }
    }))
  }
  var runtime = 0;
  async function get(params) {
    try {
      console.log('logado');
    } catch (error) {
      console.log('Não logado');
    }
    var data3 = [];
    var data4 = [];
    if (runtime == 0) {
      runtime = 1;
      const querySnapshot = await getDocs(collection(db, "clientes"));
      querySnapshot.forEach((doc) => {
        data3.push(doc.data());
      });
      var detec = localStorage.getItem('detect');
      console.log('DETECTADO => ', detec, detec == null);
      if (detec == null) {
        setClienteNovo(true);
        localStorage.setItem('detect', 'detectado');
      } else {
        setClienteNovo(false);
      }
      for (let i = 0; i < data3.length; i++) {
        var datetime1 = new Date();
        var datetime2 = new Date(data3[i].expiracaoHoras);
        var diferencaEmMilissegundos = datetime2.getTime() - datetime1.getTime();
        var diferencaEmMinutos = diferencaEmMilissegundos / (1000 * 60);
        console.log(`A diferença entre ${datetime1} e ${datetime2} é de ${diferencaEmMinutos} minutos.`);
        if (diferencaEmMinutos > 0) {
          data4.push(data3[i]);
        }
      }
      for (let i = 0; i < data3.length; i++) {
        var datetime1 = new Date();
        var datetime2 = new Date(data3[i].expiracaoHoras);
        var diferencaEmMilissegundos = datetime2.getTime() - datetime1.getTime();
        var diferencaEmMinutos = diferencaEmMilissegundos / (1000 * 60);
        console.log(`A diferença entre ${datetime1} e ${datetime2} é de ${diferencaEmMinutos} minutos.`);
        if (diferencaEmMinutos < 0) {
          data4.push(data3[i]);
        }
      }
      setData(data4);
      setDataB(data4);
    }
  }



  const [states, setStates] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [uf, setUf] = useState('');
  const [cidade, setCidade] = useState('');

  // Função para buscar os estados
  const fetchStates = async () => {
    try {
      const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
      const data = await response.json();
      const sortedStates = data.sort((a, b) => a.nome.localeCompare(b.nome));
      setStates(sortedStates); // Armazena os estados ordenados
    } catch (error) {
      console.error('Erro ao buscar os estados:', error);
    }
  };

  // Atualiza os municípios quando o estado (UF) for alterado
  useEffect(() => {
    if (uf) {
      fetchMunicipalities(uf);
    }
  }, [uf]);
  

  useEffect(() => {
    // Chama a função quando o componente é montado
    fetchStates();
  }, []);


   // Função para buscar os municípios com base na UF selecionada
   const fetchMunicipalities = async (selectedUf) => {
    try {
      const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`);
      const data = await response.json();
      setMunicipalities(data); // Armazena os municípios
    } catch (error) {
      console.error('Erro ao buscar os municípios:', error);
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <div>
      <Header />
      {showModal &&
        <div className='responsivecell' style={{ zIndex: '100', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'space-evenly', position: 'fixed', backgroundColor: '#FCFCFC', border: '1px solid rgba(100,100,10, 0.2)', height: '300px', borderRadius: '30px' }}>
          <div></div>
          <div style={{ textAlign: 'center' }}><h4 style={{ color: '#DCC780' }}>Site para maiores de <a style={{ color: 'red' }}>18 anos! </a><br /><br />
          </h4></div>
          <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
            <button onClick={() => navigate('/termos')} style={{ margin: '10px' }} className='btn button'>Voltar</button>
            <button onClick={() => setShowModal(false)} style={{ margin: '10px' }} className='btn button'>Continuar</button>
          </div>
        </div>
      }
      <div class="filters"
        style={{ overflow: 'hidden', position: 'absolute', top: '0px' }}
      >
        <div>
          <select 
                class="form-control gold-txt"
                value={uf}
                onChange={(e) => setUf(e.target.value)  } 
                placeholder="UF"
            >
            <option disabled value="">
               Estado
            </option>
              {states.map((state) => (
                  <option key={state.sigla} value={state.sigla}>
                    {state.nome}
                  </option>
                ))}
            </select>
        </div>
        <div>
            <select
                class="form-control gold-txt"
                value={cidade}
                onChange={(e) => setSearch(e.target.value)}
                disabled={!municipalities.length} 
              >
                <option disabled value="">
                 Cidade
                </option>
                {municipalities.map((municipio) => (
                  <option key={municipio.nome} value={municipio.nome}>
                    {municipio.nome}
                  </option>
              ))}
              </select>
        </div>
        <div
          id='closecs'
        >
          <input type="text" id="area2"
            onChange={(e) => setSearchNome(e.target.value)}
            name="name" placeholder="Nome" class="gold-txt" />
        </div>
        <div>
          <p
            id='closecs'
            class="normal-txt">Classificar por: </p>
          <input id="inputIdade"
            onChange={(e) => setSearchIdade(e.target.value)} //  ward
            placeholder="Idade" style={{ width: '80%' }}
          >
          </input>
          <div class="gold-gd" id="filters"
            onClick={() => { setModal2(true); setData(dataB) }}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={imggFilter}
              style={{ width: '50px' }}
              alt="" />
          </div>
        </div>
      </div>
      <div class="content">
        <div class="mycontainer" style={{ paddingLeft: '2.5px', paddingRight: '2.5px' }}>
          <img
            id='newFilter'
            src={imggFilter}
            onClick={() => setModal2(true)}
            style={{ width: '40px', position: 'absolute', top: '120px', right: '20px', cursor: 'pointer' }}
            alt="" />
          {
            <>
              {data.map((data, index) => {
                return <>
                  <ModalPerfil key={data.nome.toString()} arq={data} openAb={false} />
                </>
              })}
              <>
                {data.length < 1 ? <h3><a style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    setData(dataB);
                    setUf('');
                    setCidade('');
                    // document.querySelector('#area1').value = '';
                    // document.querySelector('#area2').value = '';
                    document.querySelector('#inputIdade').value = ''
                  }}>Limpar filtros</a></h3> : ''}
              </>
            </>
          }
        </div>
      </div>
      {
        modal2 &&
        <div class="modal modal-open" id="filter-profiles">
          <div class="modal-dialog">
            <div class="modal-content" style={{ height: '99%' }}>
              <div class="filter-header">
                <button type="button" class="btn-close"
                  style={{ color: '#fff', margin: '10px' }}
                  onClick={() => setModal2(false)}
                  data-bs-dismiss="modal"></button>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
                <h3 style={{ color: '#DDC982' }}>Filtros</h3>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-around', marginTop: '30px' }}>
                <div id="area">
                  <input type="text" id="area" name="name" placeholder="Cachê Máx."
                    onChange={(e) => setCacheFiltro(e.target.value)}
                    class="gold-txt" style={{ width: '160px', fontSize: '15px', paddingLeft: '40px' }} />
                  <a style={{ color: '#DDC982', position: 'absolute', left: '60px', top: '114px', fontSize: '15px' }}>R$:</a>
                </div>
                <input type="text" id="area" name="name" placeholder="Idade Máx."
                  onChange={(e) => setidadeFiltro(e.target.value)}
                  class="gold-txt" style={{ width: '160px', fontSize: '15px', paddingLeft: '16px' }} />
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '30px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Aceita cartão de crédito:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>

                  <input type="checkbox" class="sc-gJwTLC ikxBAC"
                    onChange={(e) => {
                      setCartao(e.target.checked)
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Aceita Dinheiro:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input type="checkbox"
                    onChange={(e) => { setDinheiro(e.target.checked) }}
                    class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Aceita Pix:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setPix(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Alta:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setAlta(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Beija de Língua:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setLingua(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Beijo Negro:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setNegro(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Branca:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setBranca(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Chuva Dourada:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setDourada(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Chuva Negra:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setChuvaNegra(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Depilada:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setDepilada(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Despedidas:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setDespedida(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }}>Atende a domicilio:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setDomicilio(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Aceita Duplas:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setDuplas(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Ejaculação Corpo:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setEjaCorpo(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Ejaculação Facial:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setFacial(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Fantasias:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setFantasias(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Festas e eventos:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setFestas(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Fetiches:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setFetichismo(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Garganta Profunda:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setGargantaProfunda(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Gordinha:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setGordinha(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Hotel:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setHotel(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Jantar Romântico:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setJantar(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Latina:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setLatina(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Aceita Ligação:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setLigacao(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Loira:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setLoira(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Magra:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setMagra(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Massagem Erótica:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setMassagem(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Mignon:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setMignon(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Morena:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setMorena(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Mulata:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setMulata(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Namoradinha:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setNamoradinha(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Seios naturais:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setNatural(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Negra:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setNegra(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Oral Até o Final:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setOralAteFinal(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Oral com camisinha:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setOralCamisinha(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Oral sem camisinha:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setOralSemCamisinha(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Oriental:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setOriental(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Púbis Peludo:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setPeluda(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >PSE:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setPse(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Ruiva:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setRuiva(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Sado Duro:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setSadoDuro(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Sado Suave:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setSadoSuave(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Sexo Anal:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setSexoAnal(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"/>
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Seios Silicone:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setSilicone(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Squirting:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setSquirting(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Strap On:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setStrapOn(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Swing:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setSwing(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Viagens:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                  <input
                    onChange={(e) => { setViagens(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Vídeo Chamada:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>

                  <input
                    onChange={(e) => { setVideochamada(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', marginLeft: '50px', marginTop: '10px', marginBottom: '40px' }}>
                <div style={{ color: '#DDC982', fontSize: '20px' }} >Whatsapp:</div>
                <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>

                  <input
                    onChange={(e) => { setWhatsapp(e.target.checked) }}
                    type="checkbox" class="sc-gJwTLC ikxBAC"

                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', marginTop: '10px', marginBottom: '50px' }}>

                <button class="button"
                  style={{ width: '180px' }}
                  onClick={() => {
                    filtrarMaster();
                  }}
                >Filtrar</button>

              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Home;
