import { useNavigate } from 'react-router-dom';
import Header from './Header';
import './Valores.css';

import { db, firebaseAuth } from '../utils/firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { cloneElement, useEffect, useState } from 'react';

function Valores() {

    const [logado, setLogado] = useState(false);
    const navigate = useNavigate();

    const [datas, setDatas] = useState([]);
    const [nova, setNova] = useState(false);
    const [userC, setUserC] = useState('');
    const [alerta, setAlerta] = useState(false);
    const [alertaLg, setAlertaLg] = useState(false);
    const [premium, setPremium] = useState(false);
    const [legacy, setLegacy] = useState(false);
    const [extras, setExtras] = useState(false);
    const [render, setRender] = useState(false);

    function trySetData(params) {
        if (params != undefined) {
            setDatas(params[0]);
        }
    }

    const fetchClass = async () => {
        onAuthStateChanged(firebaseAuth, (currentUser) => {
            if (currentUser) {
                setLogado(true);
                var emailE = currentUser.email;
                var transfer = [];
                var dadosinter = setInterval(async () => {
                    const classRef = doc(db, "clientes", emailE);
                    const classSnap = await getDoc(classRef);
                    console.log("Class data:", classSnap.data());
                    transfer.push(classSnap.data());
                    if (transfer.length > 0) {
                        clearInterval(dadosinter);
                        trySetData(transfer);
                        setUserC(emailE);
                        setLogado(true);
                        if (transfer[0]?.tipoPlano === 'nenhum') {
                            setPremium(false);
                        } else {
                            setPremium(true);
                        }
                        if (transfer[0]?.extras == false) {
                            setExtras(false);
                            setRender(true);
                        } else {
                            setExtras(true);
                            setRender(true);
                        }
                        if (transfer[0]?.tipoPlano == 'legacy') {
                            setLegacy(true);
                        } else {
                            setLegacy(false);
                        }
                        if (transfer[0]?.contaNova == true && transfer[0]?.tipoPlano == 'nenhum') {
                            setNova(true);
                        }
                    }
                }, 1000);
            } else {
                setLogado(false);
                setRender(true);
            }
        });
    }

    async function contaGratis(params) {
        var data55 = new Date();
        data55.setDate(data55.getDate() + 15);
        var masterData = data55.toJSON();

        await updateDoc(doc(db, 'clientes', `${userC}`), {
            expiracaoDaConta: masterData,
            contaNova: false
        });

        navigate('/');
    }

    useEffect(() => {
        try {
            fetchClass();
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            <Header />
            <div className="demo10" style={{ height: '100%' }}>
                {/* {
                    alerta &&
                    <div style={{ padding: '20px', zIndex: '100', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'space-evenly', position: 'fixed', backgroundColor: '#FCFCFC', border: '1px solid rgba(100,100,10, 0.2)', height: '300px', borderRadius: '30px' }}>
                        <div><img width={'80px'}></img></div>
                        <div style={{ textAlign: 'center' }}><h4 style={{ color: '#DCC780' }}>É preciso ter um pacote premium para contratar nosso pacote extra!</h4></div>
                        <div>
                            <button style={{ margin: '10px' }} className='btn button' onClick={() => setAlerta(false)}>Voltar</button>
                        </div>
                    </div>
                }
                {
                    alertaLg &&
                    <div className='responsivecell' style={{ padding: '20px', zIndex: '100', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'space-evenly', position: 'fixed', backgroundColor: '#FCFCFC', border: '1px solid rgba(100,100,10, 0.2)', height: '300px', borderRadius: '30px' }}>
                        <div><img width={'80px'}></img></div>
                        <div style={{ textAlign: 'center' }}><h4 style={{ color: '#DCC780' }}>Seu Plano já possui pacote extra</h4></div>
                        <div>
                            <button style={{ margin: '10px' }} className='btn button' onClick={() => setAlertaLg(false)}>Voltar</button>
                        </div>
                    </div>
                }
                <div className="container">
                    <h4 className="py-4 text-center" style={{ color: '#dcc780', fontSize: '30px', fontWeight: '800' }}>Conheça nossos Pacotes</h4>
                    {
                        render &&
                        <div className="row" style={{ justifyContent: 'space-evenly' }}>
                            {nova ?
                                null
                                :
                                <div className="col-md-3 col-sm-6">
                                    <div className="pricingTable10">
                                        <div className="pricingTable-header">
                                            <h3 className="heading">Extra</h3>
                                            <span className="price-value">
                                                <span className="currency">R$</span> 49,90
                                            </span>
                                        </div>
                                        <div className="pricing-content">
                                            <ul>
                                                <li>+ 5 fotos no seu perfil</li>
                                                <li>1 vídeo</li>
                                                <li style={{ color: 'transparent' }}>a</li>
                                            </ul>
                                            {
                                                extras ?
                                                    <a style={{ cursor: 'pointer' }} onClick={() => setAlertaLg(true)} className="read">Adicionar</a>
                                                    :
                                                    <a style={{ cursor: 'pointer' }} onClick={() => navigate(`${logado ? '/comprarextra' : '/register?register=register'}`)} className="read">Adicionar</a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-md-3 col-sm-6">
                                <div className="pricingTable10">
                                    <div className="pricingTable-header">
                                        <h3 className="heading">Standard</h3>
                                        <span className="price-value">
                                            <span className="currency">R$</span> 79,90
                                        </span>
                                    </div>
                                    <div className="pricing-content">
                                        <ul>
                                            <li>20 subidas com destaque no mês (não cumulativas)</li>
                                            <li>Mensal</li>
                                        </ul>
                                        <a style={{ cursor: 'pointer' }} onClick={() => navigate(`${logado ? '/comprarstandard' : '/register?register=register'}`)} className="read">Adicionar</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="pricingTable10">
                                    <div className="pricingTable-header">
                                        <h3 className="heading">VIP</h3>
                                        <span className="price-value">
                                            <span className="currency">R$</span> 109,90
                                        </span>
                                    </div>
                                    <div className="pricing-content">
                                        <ul>
                                            <li>40 subidas com destaque no mês (não cumulativas)</li>
                                            <li>Mensal</li>
                                        </ul>
                                        <a style={{ cursor: 'pointer' }} onClick={() => navigate(`${logado ? '/comprarvip' : '/register?register=register'}`)} className="read">Adicionar</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="pricingTable10">
                                    <div className="pricingTable-header">
                                        <h3 className="heading">Legacy</h3>
                                        <span className="price-value">
                                            <span className="currency">R$</span> 169,90
                                        </span>
                                    </div>
                                    <div className="pricing-content">
                                        <ul>
                                            <li>60 subidas com destaque no mês (não cumulativas)</li>
                                            <li>Destaque permanente</li>
                                            <li>Pacote Extra incluso</li>
                                            <li>Mensal</li>
                                        </ul>
                                        <a style={{ cursor: 'pointer' }} onClick={() => navigate(`${logado ? '/comprarlegacy' : '/register?register=register'}`)} className="read">Adicionar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                    <div style={{ border: '5px solid #dcc780', textAlign: 'center', marginTop: '50px', padding: '20px' }}>
                        <h2 style={{ color: '#dcc780' }}>O que é uma subida ?</h2> <br></br>
                        <span style={{ color: '#555555', fontSize: '17px', fontWeight: '400' }}>Subida nada mais é quando seu anuncio aparece no topo do site aumentando as probabilidades de captar um cliente, com bordas douradas e animadas, destacando-se dos demais, com duração de 12 horas no seu anúncio.</span>
                        <h2 style={{ color: '#dcc780', marginTop: '20px' }}>O que é um Pacote Extra ?</h2> <br></br>
                        <span style={{ color: '#555555', fontSize: '17px', fontWeight: '400' }}>No Pacote Extra liberaremos mais 5 espaços para você adicionar fotos.</span>
                    </div>
                {/* </div> */}
            </div>
        </>
    );
}

export default Valores;
