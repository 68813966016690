
import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { db, firebaseAuth, storage } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc } from 'firebase/firestore';


function UsarSubida() {

const navigate = useNavigate();

const [datas, setDatas] = useState([]);
const [subidasCount, setSubidasCount] = useState(0);
const [dellay, setDellay] = useState(false);
const [qtdSubida, setQtdSubida] = useState();
const [valor, setValor] = useState();
const [whatsapp, setWhatsapp] = useState();

const queryParameters = new URLSearchParams(window.location.search);
const type = queryParameters.get("email");





var contMaster = 0;
function validadeMaster(params, renew, plano, subidasSmn) {
  contMaster = contMaster + 1;

  if(contMaster == 1){
  var dataAtual = new Date();
    console.log('MASTER MASTER MASTER ',params, renew, plano, subidasSmn);
  var vencimento = new Date(params);
  console.log('renew', renew);
  var diaVencimento = vencimento.getTime();
  var diaAtual = dataAtual.getTime();
  console.log('vencimento = ',vencimento);
  console.log('Dia Atual = ',dataAtual);
  var vintetrez = 86400000 * 23;
  var dezesseis = 86400000 * 16;
  var nove = 86400000 * 9;
  var sete = 86400000 * 7;
  var diferenca = diaVencimento - diaAtual;
  var contagemSubidasSemana;
  if(plano == "nenhum") {contagemSubidasSemana = 0; console.log('NENHUM PLANO')}
  if(plano == "standard") {contagemSubidasSemana = 5}
  if(plano == "vip") {contagemSubidasSemana = 10; console.log('PLANO VIP')}
 



    console.log(diferenca);
  if(diaVencimento - diaAtual > vintetrez){ console.log('Primeira Semana'); }







  if(diaVencimento - diaAtual < vintetrez && diaVencimento - diaAtual > dezesseis){ 
    console.log('Segunda Semana');
  
    if(renew == 1 && subidasSmn != contagemSubidasSemana && plano != 'nenhum') {
      // ward
       updateDoc(doc(db, 'clientes', `${type}`), {
        subidasSemana: contagemSubidasSemana,
        renew: 2
      });
    }
  
  }










  if(diaVencimento - diaAtual < dezesseis && diaVencimento - diaAtual > sete){ console.log('Terceira semana');


  if(renew == 1 || renew == 2 && subidasSmn != contagemSubidasSemana && plano != 'nenhum') {
    // ward
     updateDoc(doc(db, 'clientes', `${type}`), {
      subidasSemana: contagemSubidasSemana,
      renew: 3
    });
  }




}





  if(diaVencimento - diaAtual < sete){ console.log('Quarta Semana'); 


  if(renew == 1 || renew == 2 || renew == 3 && subidasSmn != contagemSubidasSemana && plano != 'nenhum') {
    // ward
     updateDoc(doc(db, 'clientes', `${type}`), {
      subidasSemana: contagemSubidasSemana,
      renew: 4
    });
  }



}
   
}}




function trySetData(params) {
if(params != undefined){
setDatas(params[0]);
}

}




const fetchClass = async () => {
    

  var transfer = [];
  var dadosinter = setInterval( async () => {
  const classRef = doc(db, "clientes", type);
  const classSnap = await getDoc(classRef);
  console.log("Class data:", classSnap.data());
  transfer.push(classSnap.data());
  if(transfer.length > 0){
    clearInterval(dadosinter);
    trySetData(transfer);
    setDellay(true);
    setSubidasCount(transfer[0].subidasSemana);
    validadeMaster(transfer[0].expiracaoDaConta, transfer[0].renew, transfer[0].tipoPlano, transfer[0].subidasSemana);
  }
}, 1000);


const classRef = doc(db, "configuracoes", 'pacote_subida');
const classSnap = await getDoc(classRef);

setQtdSubida(classSnap.data().qtd_subida);
setValor(classSnap.data().valor);
setWhatsapp(classSnap.data().whatsapp);

}







useEffect(() => {
  fetchClass();
}, []);







async function subiu(params) {


    var data = new Date();
  
  
    data.setHours(data.getHours() + 12);


  var subidaTempo = data.toJSON();




  var updateSubidas = datas.subidasSemana - 1;

  await updateDoc(doc(db, 'clientes', `${type}`), {

    subidasSemana: updateSubidas,
    expiracaoHoras: subidaTempo,
  });
  navigate('/');
  
}






  return (
<>
<Header/>

<div className="demo10" style={{height: '100%'}}>
  <div className="container">

      <div className="row"
      style={{justifyContent: 'space-evenly'}}

      >
      
   {
    dellay && 
    <div className="col-md-8 col-sm-6"style={{borderRadius: '40px'}} >
    <div className="pricingTable20" style={{borderRadius: '40px'}}>


        <div className="pricing-content"style={{borderRadius: '40px'}} >
            
            
        <h4 className="py-4 text-center"
style={{color: '#dcc780', fontSize: '30px', fontWeight: '600',
}}

>USAR SUBIDA</h4>
            
            <ul>
        
                <li style={{display: 'flex',  alignItems: 'center', justifyContent: 'center', fontWeight: '100', marginBottom: '160px'}}>

            Usar subida para destacar seu perfil. 
            {
              subidasCount > 0 &&
              <>
                     <br></br> <br></br>
            Subidas restantes: {`${subidasCount}`}
              </>
            }
     
          
                </li>

            </ul>

            <div style={{display: 'flex', flexDirection: 'column',  alignItems: 'center'}}>
              <h4>Quer mais subidas?</h4>
              <h4>Pacote com {qtdSubida} subidas por {valor}</h4>
              <h4>Entre em contato nesse Número abaixo</h4>
              <div style={{display: 'flex', flexDirection: 'row',  alignItems: 'center'}}>
                <img src="/static/media/whats2.38bdd2bee6b339146fb3.png" style={{width: '25px', cursor: 'pointer'}}></img><h4>{whatsapp}</h4>
              </div>
              <span>Cada Subida tem a duração de 12 Horas</span>
            </div>

            { // ward
              datas.subidasSemana > 0 ?
              <button href="#"
              onClick={() => subiu() }
              className="btn button" style={{margin: '20px'}}>Usar subida</button>
              :
               <h1 style={{margin: '20px'}}>Você não tem nenhuma subida</h1>
            }

           
           
           
            <button href="#"
          onClick={()=> navigate('/anunciante')}
            className="btn button" style={{margin: '20px'}}>Voltar</button>
        </div>
    </div>
</div>
   }
         




      </div>
  </div>
</div>


</>

  
  );
}

export default UsarSubida;
