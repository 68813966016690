import './Header.css';
import lgimg from "../assets/71308dd1778ca831878299492e82271f.png";
import twoimg from "../assets/sand_button.png";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { db, firebaseAuth } from '../utils/firebase-config';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';

function Header() {
    const navigate = useNavigate();
    const [logado, setLogado] = useState(false);
    const [comAnuncio, setComAnuncio] = useState(true);
    const [extras, setExtras] = useState(true);
    const [mail, setMail] = useState('');
    const [menu, setMenu] = useState(false);
    const auth = getAuth();

    function name(params) {
        setComAnuncio(params.ativo);
        setExtras(params.extras);
        setLogado(true);
        setMail(params.email);
    }

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const uid = user.uid;
                const classRef = doc(db, "clientes", user.email);
                const classSnap = await getDoc(classRef);
                if(classSnap.exists()){
                    const offHeader = classSnap.data();
                    if (offHeader !== undefined) {
                        name(offHeader)
                    }
                } else {
                    // faz logout no firebase
                    // const deleted = await deleteDoc(classRef);
                    // console.log(deleted);
                    // console.log('teste 2');
                    // await signOut(firebaseAuth);
                    // setLogado(false)
                    // navigate('/');
                }
            }
        });
    }, [])

    return (
        <div className='fix001'>
            <div className="header" style={{ position: 'fixed', top: '0', zIndex: '100' }}>
                <div className="cont-header">
                    <div style={{ display: 'flex', justifyContent: 'start' }}>
                        <img src={lgimg} alt="" className="logo" onClick={() => navigate('/')} />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {logado ?
                            <div className="dropdown" style={{ zIndex: 1000 }}>
                                <img src={twoimg} alt="" className="menu dropbtn"
                                    style={{ width: '50px', height: '50px', marginTop: '5px' }} />
                                <div className="dropdown-content" style={{ zIndex: 1000 }}>
                                    <a onClick={() => navigate('/anunciante')} style={{ color: '#dcc780', zIndex: 100, cursor: 'pointer' }}>Painel de Controle</a>
                                    {comAnuncio === true ? '' :
                                        <a style={{ color: '#dcc780', zIndex: 100, cursor: 'pointer' }} onClick={() => navigate(`/${extras ? 'atualizarextraahiuaa1589spefsou8upop9wegp9wejgç3j2m?email=' : ''}`)}>Publicar Anúncio</a>
                                    }
                                    <a style={{ color: '#dcc780', zIndex: 100, cursor: 'pointer' }} onClick={() => { signOut(firebaseAuth); setLogado(false) }}>Sair</a>
                                </div>
                            </div>
                            :
                            <div className="dropdown" style={{ zIndex: 1000 }}>
                                <img src={twoimg} alt="" className="menu dropbtn"
                                    onClick={() => setMenu(true)}
                                    style={{ width: '50px', height: '50px', marginTop: '5px' }} />
                                {menu &&
                                    <div className="dropdown-content" style={{ zIndex: 1000 }} onMouseLeave={() => setMenu(false)}>
                                        <a style={{ color: '#dcc780', zIndex: 100, cursor: 'pointer' }} onClick={() => navigate('/login?tohome=logout')}>Entrar</a>
                                        <a style={{ color: '#dcc780', zIndex: 100, cursor: 'pointer' }} onClick={() => navigate('/register?register=register')}>Criar conta grátis</a>
                                        <a style={{ color: '#dcc780', zIndex: 100, cursor: 'pointer' }} onClick={() => navigate('/valores')}>Subidas</a>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
