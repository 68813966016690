import './Anunciante.css';
import imgAn from '../assets/local_icon.png';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

import { signOut, onAuthStateChanged } from 'firebase/auth';
import { db, firebaseAuth } from '../utils/firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';




function Anunciante() {


  const [validade, setValidade] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [bknn, setBknn] = useState('none');
  const [currentPlan, setCurrentPlan] = useState('');
  const [faltaDias, setFaltaDias] = useState(0);
  onAuthStateChanged(firebaseAuth, (currentUser) => {
    if (!currentUser) { navigate("/login") };
  });



  function onplan(params, params2) {

    var expira = new Date(params2);
    var atualData = new Date();

    var diferencaData55 = expira - atualData;
    var diferencaData66 = diferencaData55 / 1000 / 60 / 60 / 24;
    var diferencaData = String(diferencaData66).slice(0, 2);
    var plano = '';

    if (params == 'nenhum') { plano = 'Plano GRÁTIS' }
    if (params == 'vip') { plano = 'Plano VIP' }
    if (params == 'legacy') { plano = 'Plano LEGACY' }
    if (params == 'standard') { plano = 'Plano STANDARD' }

    setCurrentPlan(plano);
    setFaltaDias(diferencaData);
    console.log('atual data ', atualData.getDate());
    console.log('expiração data ', expira.getDate());
    console.log('DIFERENÇA DATA = ', diferencaData);
  }


  function validadeMaster(params) {



    const datetime155 = new Date(); // data atual
    const datetime255 = new Date(params); // data com + horas

    const diferencaEmMilissegundos55 = datetime255.getTime() - datetime155.getTime();
    const diferencaEmMinutos55 = diferencaEmMilissegundos55 / (1000 * 60);

    console.log(diferencaEmMinutos55);
    if (diferencaEmMinutos55 <= 0) {
      setValidade(false);
      setBknn('block');
      setMensagem('Por favor escolha um plano para seguir anunciando.');
    }

    if (diferencaEmMinutos55 > 0) {
      setValidade(true);

    }
  }

  const fetchClass = async () => {

    var transfer = [];
    var dadosinter = setInterval(async () => {
      if (firebaseAuth.currentUser) {
        const classRef = doc(db, "clientes", firebaseAuth.currentUser.email);
        const classSnap = await getDoc(classRef);
        transfer.push(classSnap.data());
        setValidade(true);
        // if (transfer.length > 0 && classSnap.exists()) {
        //   clearInterval(dadosinter);
        //   validadeMaster(transfer[0].expiracaoDaConta);
        //   onplan(transfer[0].tipoPlano, transfer[0].expiracaoDaConta);
        // }
      }
    }, 1000);

  }

  useEffect(() => {

    fetchClass();

  }, [])

  const navigate = useNavigate();
  return (

    <div>
      <Header />

      {validade ?
        <div _ngcontent-bss-c4="" >
          <div _ngcontent-bss-c4="">
            <div _ngcontent-bss-c4="" className="containter">
              {/* <h6 style={{ top: '120px', marginLeft: '30px', position: 'absolute', color: '#dcc780' }}>{`${currentPlan}, ${faltaDias} dias para expiração`}</h6> */}
            </div>
          </div>
          <div _ngcontent-bss-c4="" className="_container">
            <div _ngcontent-bss-c4="" className="services grid flex-wrap justify-content-center">
              {/* <div _ngcontent-bss-c4="" className="service-item" onClick={() => navigate('/valores')}>
                <a _ngcontent-bss-c4="" className="service-link">
                  <i _ngcontent-bss-c4="" className="service-icone icone-autorizacao" ng-reflect-ng-class="icone-autorizacao"></i>
                  <span _ngcontent-bss-c4="" className="service-titulo">VALORES</span>
                </a>
              </div> */}
              <div _ngcontent-bss-c4="" className="service-item"
                onClick={() => navigate('/termos')}
              >
                <a _ngcontent-bss-c4="" className="service-link">
                  <i _ngcontent-bss-c4="" className="service-icone icone-fxd" ng-reflect-ng-class="icone-fxd"></i>
                  <span _ngcontent-bss-c4="" className="service-titulo">TERMOS LEGAIS</span>
                  <span _ngcontent-bss-c4="" className="service-legenda"></span>
                </a>
              </div>
              <div _ngcontent-bss-c4="" className="service-item"
                onClick={() => navigate(`/suporte?email=${firebaseAuth.currentUser.email}`)}
              >
                <a _ngcontent-bss-c4="" className="service-link">
                  <i _ngcontent-bss-c4="" className="service-icone icone-divida" ng-reflect-ng-class="icone-divida">
                  </i><span _ngcontent-bss-c4="" className="service-titulo"> SUPORTE
                  </span>
                  <span _ngcontent-bss-c4="" className="service-legenda"
                  //   style="font-weight: 900;"
                  >  </span>
                </a>
              </div>
              <div _ngcontent-bss-c4="" className="service-item"
                onClick={() => navigate(`/atualizar`)}
              >
                <a _ngcontent-bss-c4="" className="service-link">
                  <a style={{ color: 'black', fontSize: '27px' }}>✃</a><span _ngcontent-bss-c4="" className="service-titulo"> EDITAR ANÚNCIO
                  </span>
                  <span _ngcontent-bss-c4="" className="service-legenda"
                  //   style="font-weight: 900;"
                  >  </span>
                </a>
              </div>
              <div _ngcontent-bss-c4="" className="service-item"
                onClick={() => navigate(`/usarextra?email=${firebaseAuth.currentUser.email}`)}
              >
                <a _ngcontent-bss-c4="" className="service-link">
                  <i _ngcontent-bss-c4="" className="service-icone icone-transporte" ng-reflect-ng-class="icone-transporte">
                  </i><span _ngcontent-bss-c4="" className="service-titulo"> PACOTE EXTRA </span>
                  <span _ngcontent-bss-c4="" className="service-legenda"
                  //style="font-weight: 900;"
                  >
                  </span>
                </a>
              </div>

              <div _ngcontent-bss-c4="" className="service-item"
                onClick={() => navigate(`/usarsubida?email=${firebaseAuth.currentUser.email}`)}
              >
                <a _ngcontent-bss-c4="" className="service-link">
                  <i _ngcontent-bss-c4="" className="service-icone icone-danos" ng-reflect-ng-class="icone-danos">
                  </i><span _ngcontent-bss-c4="" className="service-titulo">  USAR PACOTE SUBIDA
                  </span>
                  <span _ngcontent-bss-c4="" className="service-legenda"
                  //  style="font-weight: 900;"
                  >
                  </span>
                </a>
              </div>
              <div _ngcontent-bss-c4="" className="service-item"
                onClick={() => navigate('/verificacao')}
              >
                <a _ngcontent-bss-c4="" className="service-link">
                  <i _ngcontent-bss-c4="" className="service-icone icone-convenio" ng-reflect-ng-class="icone-convenio">
                  </i>
                  <span _ngcontent-bss-c4="" className="service-titulo"> SELO VERIFICAÇÃO
                  </span>
                  <span _ngcontent-bss-c4="" className="service-legenda"
                  //  style="font-weight: 900;"
                  >
                  </span>
                </a>
              </div>

              <div _ngcontent-bss-c4="" className="service-item"
                onClick={() => navigate(`/excluir?email=${firebaseAuth.currentUser.email}`)}
              >
                <a _ngcontent-bss-c4="" className="service-link">
                  <i _ngcontent-bss-c4="" className="service-icone icone-eventos" ng-reflect-ng-class="icone-eventos">
                  </i>
                  <span _ngcontent-bss-c4="" className="service-titulo">
                    EXCLUIR ANÚNCIO
                  </span>
                  <span _ngcontent-bss-c4="" className="service-legenda"
                  //  style="font-weight: 900;"
                  >
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        : <div _ngcontent-bss-c4="" >
          <div _ngcontent-bss-c4="">
            <div _ngcontent-bss-c4="" className="containter">
            </div>
          </div>
          <div _ngcontent-bss-c4="" className="_container">
            <div _ngcontent-bss-c4="" className="services" style={{ flexFlow: 'column nowrap', alignItems: 'center' }}>

              <br />

              <h4>{mensagem}</h4>

              <button className='btn button' style={{ marginTop: '30px', display: `${bknn}` }} onClick={() => navigate('/valores')}>Planos</button>


            </div>
          </div>
        </div>}
    </div>



  );
}

export default Anunciante;
