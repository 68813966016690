import React, { useEffect, useState } from "react";
import imgAn from '../assets/local_icon.png';
import whatsAppIcon from '../assets/whatsapp_icon.png';
import whats2 from "../assets/whats2.png";
import premiumImg from '../assets/crown.png';
import verifyImg from '../assets/verify.png';
import './modal.css';
import videoup from '../assets/videopng.jpg';
import localico from '../assets/local_icon_black.png';
function ModaPerfil(data) {




  const [abrirModal, setAbrirModal] = useState('');
  const [whatsAppOpen, setWhatsAppOpen] = useState(false);
  const [upper, setUpper] = useState(false);
  const [validade, setValidade] = useState(false);
  const [openVid, setOpenVid] = useState(false);





  function validadeMaster(params) {

    const datetime155 = new Date(); // data atual
    const datetime255 = new Date(data.arq.expiracaoDaConta); // data com + horas

    const diferencaEmMilissegundos55 = datetime255.getTime() - datetime155.getTime();
    const diferencaEmMinutos55 = diferencaEmMilissegundos55 / (1000 * 60);
    console.log(`A diferença entre aaaaaaaaaa ${datetime155} e ${datetime255} é de ${diferencaEmMinutos55} minutos.`);
    if (diferencaEmMinutos55 <= 0) {
      setValidade(false);
    }

    if (diferencaEmMinutos55 > 0) {
      setValidade(true);
    }
  }






  useEffect(() => {

    const datetime1 = new Date(); // data atual
    const datetime2 = new Date(data.arq.expiracaoHoras); // data com + horas

    const diferencaEmMilissegundos = datetime2.getTime() - datetime1.getTime();
    const diferencaEmMinutos = diferencaEmMilissegundos / (1000 * 60);

    setUpper(true);
    console.log(`A diferença entre ${datetime1} e ${datetime2} é de ${diferencaEmMinutos} minutos.`);
    if (diferencaEmMinutos <= 0) {
      setUpper(false);
    }

    if (diferencaEmMinutos > 0) {
      setUpper(true);
    }

    setValidade(true);



    // validadeMaster();

  }, [])


  return (

    <>
      {validade &&
        <>

          {data.arq.ativo ?
            <>
              {
                upper ?
                  <>

                    <div class="card-ac card3" style={{ backgroundImage: `url(${data.arq.url01})` }} onClick={() => setAbrirModal('modal-open')}>

                      <div className="border3"></div>
                      <div class="contact" >
                        <img
                          src={premiumImg}
                          style={{ position: 'absolute', bottom: '310px', left: '200px' }}
                          alt="" class="whats" />


                        <div>



                          <p class="name" style={{ display: 'flex' }}>{`${data.arq.nome.slice(0, 22)}, ${data.arq.idade}`}  <div style={{ marginLeft: '-25px', marginTop: '-2px' }}>
                            {
                              data.arq.verificado ?
                                <img
                                  src={verifyImg}
                                  style={{ width: '20px', height: '20px' }}
                                  alt="" class="whats" />
                                : ''
                            }



                          </div>   </p>
                          <div

                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              src={imgAn}
                              alt="" class="local" />
                            <p class="city">{data.arq.cidade}</p>
                          </div>
                        </div>




                        <img

                          src={whatsAppIcon}
                          alt="" class="whats" />

                      </div>




                    </div>
                    <div class={`modal ${abrirModal}`} id="acompanhante-profile">
                      <div class="modal-dialog">
                        <div class="modal-content">


                          <div class="modal-header" style={{ backgroundImage: `url(${data.arq.url02})`, backgroundRepeat: 'no-repeat', }}>
                            <button type="button"
                              onClick={() => setAbrirModal('')}
                              class="btn-close" style={{ color: '#fff' }} data-bs-dismiss="modal"></button>
                          </div>
                          {/* <div style={{ width: '130px', position: 'absolute', left: '7px', top: '85px', height: '130px', border: '4px solid white', backgroundImage: `url(${data.arq.url01})`, backgroundSize: '100%', zIndex: '100', borderRadius: '50%' }}>


                          </div> */}


                          <div class="modal-body" style={{ marginTop: '-30px' }} >
                            {/* <div>
                              <p class="city" style={{ color: 'transparent' }}>Modelo Exemplo</p>
                            </div> */}
                            <div>
                              <p class="city">{`${data.arq.nome.slice(0, 22)}, ${data.arq.idade}`}</p>
                            </div>
                            <div>
                              <img src={localico} alt="" class="local" />
                              <p class="city">{data.arq.cidade}</p>
                            </div>
                            <div>
                              <a href={`https://wa.me/55${data.arq.telefone.replace('+55', '').replace(/\D/g, '')}?text=Olá,%20lhe%20vi%20em%20StudioAcompanhante✨%20e%20gostaria%20de%20marcar%20um%20encontro%20com%20você.`} target="_blank" rel="noopener noreferrer">

                                <img src={whats2}

                                  style={{ width: '40px', cursor: 'pointer' }}
                                // onClick={() => { setWhatsAppOpen(true) }}
                                ></img>
                              </a>
                            </div>
                          </div>

                          <div style={{ marginTop: '50px', textAlign: 'center' }}>
                            <h2 style={{ fontSize: 'large' }}>
                              Serviços
                            </h2>
                            <div style={{ margin: '5px 20px', textAlign: 'justify', display: 'flex', flexFlow: 'row wrap' }}
                            >
                              {data.arq.aceitaCredito && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Aceita Crédito</div>}
                              {data.arq.aceitaDinheiro && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Aceita Dinheiro</div>}
                              {data.arq.aceitaPix && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Aceita Pix</div>}
                              {<div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>{`R$: ${data.arq.precoPorHora}`}</div>}
                              {data.arq.alta && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Alta</div>}
                              {data.arq.beijo && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Beijo Lingua</div>}
                              {data.arq.beijoNegro && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Beijo Negro</div>}
                              {data.arq.branca && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Branca</div>}
                              {data.arq.chuvaDourada && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Chuva Dourada</div>}
                              {data.arq.chuvaNegra && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Chuva Negra</div>}
                              {data.arq.depilada && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Depilada</div>}
                              {data.arq.domicilio && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Atende a domicílio</div>}
                              {data.arq.duplas && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Aceita Duplas</div>}
                              {data.arq.ejaculacaoCorpo && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Ejaculação Corpo</div>}
                              {data.arq.facial && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Facial</div>}
                              {data.arq.fantasias && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Fantasias</div>}
                              {data.arq.fetichismo && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Fetichismo</div>}
                              {data.arq.gargantaProfunda && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Garganta Profunda</div>}
                              {data.arq.gordinha && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Gordinha</div>}
                              {data.arq.hotel && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Hotel</div>}
                              {data.arq.jantarRomantico && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Jantar Romântico</div>}
                              {data.arq.latina && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Latina</div>}
                              {data.arq.ligacao && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Atende Ligação</div>}
                              {data.arq.loira && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Loira</div>}
                              {data.arq.magra && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Magra</div>}
                              {data.arq.massagem && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Massagem Erótica</div>}
                              {data.arq.mignon && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Mignon</div>}
                              {data.arq.morena && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Morena</div>}
                              {data.arq.mulata && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Mulata</div>}
                              {data.arq.namoradinha && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Namoradinha</div>}
                              {data.arq.natual && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Seios Naturais</div>}
                              {data.arq.negra && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Negra</div>}
                              {data.arq.oralAteFinal && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Oral até o final</div>}
                              {data.arq.oralCamisinha && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Oral com camisinha</div>}
                              {data.arq.oralSemCamisinha && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Oral sem camisinha</div>}
                              {data.arq.oriental && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Oriental</div>}
                              {data.arq.peluda && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Púbis Peludo</div>}
                              {data.arq.pse && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>PSE</div>}
                              {data.arq.ruiva && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Ruiva</div>}
                              {data.arq.sadoDuro && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Sado Duro</div>}
                              {data.arq.sadoSuave && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Sado Suave</div>}
                              {data.arq.sexoAnal && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Sexo Anal</div>}
                              {data.arq.silicone && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Seos Silicone</div>}
                              {data.arq.squirting && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Squirting</div>}
                              {data.arq.strapOn && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Strap On</div>}
                              {data.arq.swing && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Swing</div>}
                              {data.arq.verificado && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Perfil Ferificado</div>}
                              {data.arq.viagens && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Viagens</div>}
                              {data.arq.videoChamada && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Vídeo Chamada</div>}
                              {data.arq.whatsApp && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>WhatsApp</div>}

                            </div>
                            <h2 style={{ fontSize: 'large', marginTop: '20px' }}>
                              Sobre Mim
                            </h2>
                            <p style={{ margin: '5px 20px', textAlign: 'justify' }}
                            >
                              {data.arq.sobre}
                            </p>
                            <h2 style={{ fontSize: 'large', marginTop: '20px' }}>
                              Galeria
                            </h2>
                            <div class="container mt-4">
                              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">

                                <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                  <img src={data.arq.url01} style={{ width: '100%' }}></img>
                                </div>
                                <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                  <img src={data.arq.url02} style={{ width: '100%' }}></img>
                                </div>

                                {data.arq.url03 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url03} style={{ width: '100%' }}></img>
                                  </div>
                                }


                                {data.arq.url04 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url04} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url05 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url05} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url06 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url06} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url07 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url07} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url08 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url08} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url09 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url09} style={{ width: '100%' }}></img>
                                  </div>
                                }

                                {data.arq.videoUp &&
                                  <>

                                    <div class="col" style={{ marginTop: '50px', backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '400px', width: '100%' }}>
                                      <h4 style={{ marginBottom: '40px' }}>Vídeo</h4>


                                      <video autoplay controls loop id="myVideo" style={{ width: '100%' }}>
                                        <source src={data.arq.videoUp} type="video/mp4" />
                                        Your browser does not support HTML5 video.
                                      </video>




                                    </div>
                                  </>
                                }



                              </div>
                            </div>
                          </div>

                          {/* <div style={{ marginTop: '50px', textAlign: 'center' }}>
                            <h2 style={{ fontSize: 'large' }}>
                              Sobre Mim
                            </h2>
                            <p style={{ margin: '5px 20px', textAlign: 'justify' }}
                            >
                              {data.arq.sobre}
                            </p>
                            <h2 style={{ fontSize: 'large', marginTop: '20px' }}>
                              Galeria
                            </h2>
                            <div class="container mt-4">
                              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                                <div class="col" style={{ backgroundImage: `url(${data.arq.url01})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>

                                </div>
                                <div class="col" style={{ backgroundImage: `url(${data.arq.url02})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>
                                </div>

                                {data.arq.url03.length > 2 &&
                                  <div class="col" style={{ backgroundImage: `url(${data.arq.url03})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>
                                  </div>
                                }


                                {data.arq.url04.length > 2 &&
                                  <div class="col" style={{ backgroundImage: `url(${data.arq.url04})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>
                                  </div>
                                }
                                {data.arq.url05.length > 2 &&
                                  <div class="col" style={{ backgroundImage: `url(${data.arq.url05})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>
                                  </div>
                                }
                                {data.arq.url06.length > 2 &&
                                  <div class="col" style={{ backgroundImage: `url(${data.arq.url06})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>
                                  </div>
                                }
                                {data.arq.url07.length > 2 &&
                                  <div class="col" style={{ backgroundImage: `url(${data.arq.url07})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>
                                  </div>
                                }
                                {data.arq.url08.length > 2 &&
                                  <div class="col" style={{ backgroundImage: `url(${data.arq.url08})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>
                                  </div>
                                }
                                {data.arq.url09.length > 2 &&
                                  <div class="col" style={{ backgroundImage: `url(${data.arq.url09})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '230px' }}>
                                  </div>
                                }

                                {data.arq.videoUp.length > 2 &&
                                  <>

                                    <div class="col" style={{ marginTop: '50px', backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                      <h4 style={{ marginBottom: '40px' }}>Vídeo</h4>


                                      <video autoplay controls loop id="myVideo" style={{ width: '100%' }}>
                                        <source src={data.arq.videoUp} type="video/mp4" />
                                        Your browser does not support HTML5 video.
                                      </video>




                                    </div>
                                  </>
                                }



                              </div>
                            </div>
                          </div> */}


                          <div class="modal-footer"
                            style={{ border: 'none', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            {whatsAppOpen ?
                              <h3 style={{ marginLeft: '20px' }}>{'Telefone: ' + data.arq.telefone}</h3>
                              :
                              <p style={{ color: 'red', fontSize: 'small', fontWeight: '700', width: '80%' }}>Atenção, o Studio Acompanhantes não interfere nas negociações entre anuncianters e usuários. Para evitar ser enganado, nunca realize pagamentos antecipados</p>
                            }
                            <a href={`https://wa.me/55${data.arq.telefone.replace('+55', '').replace(/\D/g, '')}?text=Olá,%20lhe%20vi%20em%20StudioAcompanhante✨%20e%20gostaria%20de%20marcar%20um%20encontro%20com%20você.`} target="_blank" rel="noopener noreferrer">

                              <img
                                src={whats2} style={{ width: '40px', cursor: 'pointer' }}
                              //  onClick={() => {setWhatsAppOpen(true)}}

                              ></img> </a>
                          </div>

                        </div>
                      </div>
                    </div>



                  </>
                  :

                  <>

                    <div class="card-ac" style={{ backgroundImage: `url(${data.arq.url01})` }} onClick={() => setAbrirModal('modal-open')}>


                      <div class="contact" >



                        <div>



                          <p class="name" style={{ display: 'flex' }}>{`${data.arq.nome.split(' ')[0].slice(0, 11)}, ${data.arq.idade}`}  <div style={{ marginLeft: '-25px', marginTop: '-2px' }}>
                            {
                              data.arq.verificado ?
                                <img
                                  src={verifyImg}
                                  style={{ width: '20px', height: '20px' }}
                                  alt="" class="whats" />

                                : ''
                            }



                          </div>   </p>
                          <div

                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              src={imgAn}
                              alt="" class="local" />
                            <p class="city">{data.arq.cidade}</p>
                          </div>
                        </div>


                        <img
                          style={{ width: '40px' }}
                          src={whatsAppIcon}
                          alt="" class="whats" />

                      </div>




                    </div>



                    <div class={`modal ${abrirModal}`} id="acompanhante-profile">
                      <div class="modal-dialog">
                        <div class="modal-content">


                          <div class="modal-header" style={{ backgroundImage: `url(${data.arq.url02})`, backgroundRepeat: 'no-repeat', }}>
                            <button type="button"
                              onClick={() => setAbrirModal('')}
                              class="btn-close" style={{ color: '#fff' }} data-bs-dismiss="modal"></button>
                          </div>
                          {/* <div style={{ width: '130px', position: 'absolute', left: '7px', top: '85px', height: '130px', border: '4px solid white', backgroundImage: `url(${data.arq.url01})`, backgroundSize: '100%', zIndex: '100', borderRadius: '50%' }}>

                          </div> */}


                          <div class="modal-body" style={{ marginTop: '-30px' }} >
                            {/* <div>
                              <p class="city" style={{ color: 'transparent' }}>Modelo Exemplo</p>
                            </div> */}

                            {
                              data.arq.nome.split(' ')[0].length > 10 ?
                                <div>
                                  <p class="city">{`${data.arq.nome.split(' ')[0].slice(0, 8)}... ${data.arq.idade}`}</p>
                                </div>
                                :

                                <div>
                                  <p class="city">{`${data.arq.nome}, ${data.arq.idade}`}</p>
                                </div>

                            }


                            {
                              data.arq.cidade.length > 9 ?
                                <div>


                                  <img src={localico} alt="" class="local sumir01" />

                                  <p class="city">{data.arq.cidade}</p>
                                </div>
                                :
                                <div className="sumir01">


                                  <img src={localico} alt="" class="local sumir01" />

                                  <p class="city">{data.arq.cidade}</p>
                                </div>
                            }

                            <a href={`https://wa.me/55${data.arq.telefone.replace('+55', '').replace(/\D/g, '')}?text=Olá,%20lhe%20vi%20em%20StudioAcompanhante✨%20e%20gostaria%20de%20marcar%20um%20encontro%20com%20você.`} target="_blank" rel="noopener noreferrer">

                              <img src={whats2}

                                style={{ width: '40px', cursor: 'pointer' }}
                              // onClick={() => {setWhatsAppOpen(true)}}

                              ></img></a>
                          </div>

                          <div style={{ marginTop: '50px', textAlign: 'center' }}>
                            <h2 style={{ fontSize: 'large' }}>
                              Serviços
                            </h2>
                            <div style={{ margin: '5px 20px', textAlign: 'justify', display: 'flex', flexFlow: 'row wrap' }}
                            >
                              {data.arq.aceitaCredito && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Aceita Crédito</div>}
                              {data.arq.aceitaDinheiro && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Aceita Dinheiro</div>}
                              {data.arq.aceitaPix && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Aceita Pix</div>}
                              {<div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>{`R$: ${data.arq.precoPorHora}`}</div>}
                              {data.arq.alta && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Alta</div>}
                              {data.arq.beijo && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Beijo Lingua</div>}
                              {data.arq.beijoNegro && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Beijo Negro</div>}
                              {data.arq.branca && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Branca</div>}
                              {data.arq.chuvaDourada && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Chuva Dourada</div>}
                              {data.arq.chuvaNegra && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Chuva Negra</div>}
                              {data.arq.depilada && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Depilada</div>}
                              {data.arq.domicilio && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Atende a domicílio</div>}
                              {data.arq.duplas && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Aceita Duplas</div>}
                              {data.arq.ejaculacaoCorpo && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Ejaculação Corpo</div>}
                              {data.arq.facial && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Facial</div>}
                              {data.arq.fantasias && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Fantasias</div>}
                              {data.arq.fetichismo && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Fetichismo</div>}
                              {data.arq.gargantaProfunda && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Garganta Profunda</div>}
                              {data.arq.gordinha && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Gordinha</div>}
                              {data.arq.hotel && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Hotel</div>}
                              {data.arq.jantarRomantico && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Jantar Romântico</div>}
                              {data.arq.latina && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Latina</div>}
                              {data.arq.ligacao && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Atende Ligação</div>}
                              {data.arq.loira && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Loira</div>}
                              {data.arq.magra && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Magra</div>}
                              {data.arq.massagem && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Massagem Erótica</div>}
                              {data.arq.mignon && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Mignon</div>}
                              {data.arq.morena && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Morena</div>}
                              {data.arq.mulata && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Mulata</div>}
                              {data.arq.namoradinha && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Namoradinha</div>}
                              {data.arq.natual && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Seios Naturais</div>}
                              {data.arq.negra && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Negra</div>}
                              {data.arq.oralAteFinal && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Oral até o final</div>}
                              {data.arq.oralCamisinha && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Oral com camisinha</div>}
                              {data.arq.oralSemCamisinha && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Oral sem camisinha</div>}
                              {data.arq.oriental && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Oriental</div>}
                              {data.arq.peluda && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Púbis Peludo</div>}
                              {data.arq.pse && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>PSE</div>}
                              {data.arq.ruiva && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Ruiva</div>}
                              {data.arq.sadoDuro && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Sado Duro</div>}
                              {data.arq.sadoSuave && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Sado Suave</div>}
                              {data.arq.sexoAnal && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Sexo Anal</div>}
                              {data.arq.silicone && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Seos Silicone</div>}
                              {data.arq.squirting && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Squirting</div>}
                              {data.arq.strapOn && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Strap On</div>}
                              {data.arq.swing && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Swing</div>}
                              {data.arq.verificado && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Perfil Ferificado</div>}
                              {data.arq.viagens && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Viagens</div>}
                              {data.arq.videoChamada && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>Vídeo Chamada</div>}
                              {data.arq.whatsApp && <div style={{ backgroundColor: '#DCC780', display: 'flex', flexFlow: 'row nowrap', minwidth: '29px', padding: '5px 30px', textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: '3px', margin: '3px 3px' }}>WhatsApp</div>}

                            </div>
                            <h2 style={{ fontSize: 'large', marginTop: '20px' }}>
                              Sobre Mim
                            </h2>
                            <p style={{ margin: '5px 20px', textAlign: 'justify' }}
                            >
                              {data.arq.sobre}
                            </p>
                            <h2 style={{ fontSize: 'large', marginTop: '20px' }}>
                              Galeria
                            </h2>
                            <div class="container mt-4">
                              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">

                                <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                  <img src={data.arq.url01} style={{ width: '100%' }}></img>
                                </div>
                                <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                  <img src={data.arq.url02} style={{ width: '100%' }}></img>
                                </div>


                                {data.arq.url03 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url03} style={{ width: '100%' }}></img>
                                  </div>
                                }


                                {data.arq.url04 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url04} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url05 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url05} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url06 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url06} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url07 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url07} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url08 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url08} style={{ width: '100%' }}></img>
                                  </div>
                                }
                                {data.arq.url09 &&
                                  <div class="col" style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', width: '100%' }}>
                                    <img src={data.arq.url09} style={{ width: '100%' }}></img>
                                  </div>
                                }

                                {data.arq.videoUp &&
                                  <>

                                    <div class="col" style={{ marginTop: '50px', backgroundSize: '100%', backgroundRepeat: 'no-repeat', border: 'solid 10px white', height: '400px', width: '100%' }}>
                                      <h4 style={{ marginBottom: '40px' }}>Vídeo</h4>


                                      <video autoplay controls loop id="myVideo" style={{ width: '100%' }}>
                                        <source src={data.arq.videoUp} type="video/mp4" />
                                        Your browser does not support HTML5 video.
                                      </video>




                                    </div>
                                  </>
                                }



                              </div>
                            </div>
                          </div>


                          <div class="modal-footer" style={{ border: 'none', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            {whatsAppOpen ?
                              <h3 style={{ marginLeft: '20px' }}>{'Telefone: ' + data.arq.telefone}</h3>
                              :
                              <p style={{ color: 'red', fontSize: 'small', fontWeight: '700', width: '80%' }}>Atenção, o Studio Acompanhantes não interfere nas negociações entre anunciantes e usuários. Para evitar ser enganado, nunca realize pagamentos antecipados.</p>
                            }
                            <a href={`https://wa.me/55${data.arq.telefone.replace('+55', '').replace(/\D/g, '')}?text=Olá,%20lhe%20vi%20em%20StudioAcompanhante✨%20e%20gostaria%20de%20marcar%20um%20encontro%20com%20você.`} target="_blank" rel="noopener noreferrer">
                              <img src={whats2} style={{ width: '40px', cursor: 'pointer' }} alt="WhatsApp"></img>
                            </a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </>
              }
            </> : ''
          }
        </>
      }

    </>
  )
}

export default ModaPerfil;

























