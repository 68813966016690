
import './Register.css';
import React, { useEffect, useState } from 'react'
import { db, firebaseAuth, storage } from "../utils/firebase-config";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, getDoc, Firestore, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header from './Header';


// REGISTER MASTER



function Register() {

  const navigate = useNavigate();



  const [deslogado, setDeslogado] = useState(false);




  const [stepby, setStepby] = useState('none')
  const [page01, setPage01] = useState('block');
  const [page02, setPage02] = useState('none');
  const [page03, setPage03] = useState('none');
  const [page04, setPage04] = useState('none');
  const [page05, setPage05] = useState('none');
  const [page06, setPage06] = useState('none');
  const [page07, setPage07] = useState('none');



  const [emaill, setEmaill] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaCnf, setSenhaCnf] = useState('');
  const [nome, setNome] = useState('');
  const [uf, setUf] = useState('');
  const [cidade, setCidade] = useState('');
  const [idade, setIdade] = useState('');
  const [erroIdade, setErroIdade] = useState('');
  const [telefone, setTelefone] = useState('');
  const [sobre, setSobre] = useState('');

  const [precoHr, setPrecoHr] = useState('');
  const [credito, setCredito] = useState(false);
  const [pix, setPix] = useState(false);
  const [dinheiro, setDinheiro] = useState(false);
  const [ligacao, setLigacao] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [videochamada, setVideochamada] = useState(false);

  const [branca, setBranca] = useState(false);
  const [latina, setLatina] = useState(false);
  const [mulata, setMulata] = useState(false);
  const [negra, setNegra] = useState(false);
  const [oriental, setOriental] = useState(false);
  const [loira, setLoira] = useState(false);
  const [morena, setMorena] = useState(false);
  const [ruiva, setRuiva] = useState(false);
  const [alta, setAlta] = useState(false);
  const [mignon, setMignon] = useState(false);
  const [gordinha, setGordinha] = useState(false);
  const [magra, setMagra] = useState(false);
  const [silicone, setSilicone] = useState(false);
  const [natural, setNatural] = useState(false);
  const [peluda, setPeluda] = useState(false);
  const [depilada, setDepilada] = useState(false);



  const [beijo, setBeijo] = useState(false);
  const [duplas, setDuplas] = useState(false);
  const [ejaculacaoCorpo, setEjaculacaoCorpo] = useState(false);
  const [facial, setFacial] = useState(false);
  const [fantasias, setFantasias] = useState(false);
  const [massagem, setMassagem] = useState(false);
  const [namoradinha, setNamoradinha] = useState(false);
  const [oralAteFinal, setOralAteFinal] = useState(false);
  const [oralCamisinha, setOralCamisinha] = useState(false);
  const [oralSemCamisinha, setOralSemCamisinha] = useState(false);
  const [pse, setPse] = useState(false);
  const [sexoAnal, setSexoAnal] = useState(false);


  const [beijoNegro, setBeijoNegro] = useState(false);
  const [chuvaDourada, setChuvaDourada] = useState(false);
  const [chuvaNegra, setChuvaNegra] = useState(false);
  const [fetichismo, setFetichismo] = useState(false);
  const [sadoDuro, setSadoDuro] = useState(false);
  const [squirting, setSquirting] = useState(false);
  const [gargantaProfunda, setGargantaProfunda] = useState(false);
  const [sadoSuave, setSadoSuave] = useState(false);
  const [strapOn, setStrapOn] = useState(false);


  const [domicilio, setDomicilio] = useState(false);
  const [swing, setSwing] = useState(false);
  const [comLocal, setComLocal] = useState(false);
  const [despedidas, setDespedidas] = useState(false);
  const [festasEventos, setFestasEventos] = useState(false);
  const [jantarRomantico, setJantarRomantico] = useState(false);
  const [hotel, setHotel] = useState(false);
  const [viagens, setViagens] = useState(false);

  const [imageUpload, setImageUpload] = useState();
  const [imageUpload2, setImageUpload2] = useState();
  const [imageUpload3, setImageUpload3] = useState();
  const [imageUpload4, setImageUpload4] = useState();
  const [videoUpload, setVideoUpload] = useState();


  const [ativo, setAtivo] = useState(true);



  const [url01, setUrl01] = useState('');
  const [url02, setUrl02] = useState('');
  const [url03, setUrl03] = useState('');
  const [url04, setUrl04] = useState('');
  const [url05, setUrl05] = useState('');

  const [msgs, setMsgS] = useState('');
  const [msgs2, setMsgS2] = useState('');
  const [msgs3, setMsgS3] = useState('');
  const [msgs4, setMsgS4] = useState('');
  const [msgs5, setMsgS5] = useState('');
  const [msgs6, setMsgS6] = useState('');
  const [msgs7, setMsgS7] = useState('');

  const [states, setStates] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);


  // Função para buscar os estados
  const fetchStates = async () => {
    try {
      const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
      const data = await response.json();
      const sortedStates = data.sort((a, b) => a.nome.localeCompare(b.nome));
      setStates(sortedStates); // Armazena os estados ordenados
    } catch (error) {
      console.error('Erro ao buscar os estados:', error);
    }
  };

  // Atualiza os municípios quando o estado (UF) for alterado
  useEffect(() => {
    if (uf) {
      fetchMunicipalities(uf);
    }
  }, [uf]);
  

  useEffect(() => {
    // Chama a função quando o componente é montado
    fetchStates();
  }, []);


   // Função para buscar os municípios com base na UF selecionada
   const fetchMunicipalities = async (selectedUf) => {
    try {
      const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`);
      const data = await response.json();
      setMunicipalities(data); // Armazena os municípios
    } catch (error) {
      console.error('Erro ao buscar os municípios:', error);
    }
  };




  async function setMaster(params) {
    if (params == 1) {
      if (senha == senhaCnf) {
        try {
          const classRef = doc(db, "clientes", emaill);
          const classSnap = await getDoc(classRef);
          if (!classSnap.exists()) {
            setPage01('none');
            setPage02('block');
            setPage03('none');
            setPage04('none');
            return;
          } 
          setMsgS('Email já cadastrado!')
        } catch (err) {
          setMsgS(err.code);
        }
      } else {
        setMsgS('Senhas não correspondem!')
      }
    }
  
    if (params == 2) {
      if (nome.length > 3 && cidade.length > 3 && idade.length > 1 && telefone.length > 8 && sobre.length > 100 && sobre.length < 3000) {
        if (parseInt(idade) >= 18) {
          setPage01('none');
          setPage02('none');
          setPage03('block');
          setPage04('none');
        } else {
          setMsgS4('Você deve ter pelo menos 18 anos para continuar.');
        }
      } else {
        if (nome.length <= 3) { setMsgS2('Preencha seu nome!') } else { setMsgS2('') }
        if (cidade.length <= 3) { setMsgS3('Preencha seu estado e sua cidade!') } else { setMsgS3('') }
        if (idade.length <= 1) { setMsgS4('Preencha sua idade!') } else { setMsgS4('') }
        if (telefone.length <= 7) { setMsgS5('Preencha seu telefone!') } else { setMsgS5('') }
        if (sobre.length <= 100) { setMsgS6('Sua bio deve ter mais de 100 caracteres') } else { setMsgS6('') }
        if (sobre.length >= 3000) { setMsgS7('Sua bio deve ter menos de 3000 caracteres') } else { setMsgS7('') }
      }

    }

    if (params == 3) {
      setPage01('none');
      setPage02('none');
      setPage03('none');
      setPage04('block');
    }
    if (params == 4) {
      setPage01('none');
      setPage02('none');
      setPage03('none');
      setPage04('none');
      setPage05('block');

    }

    if (params == 5) {
      setPage01('none');
      setPage02('none');
      setPage03('none');
      setPage04('none');
      setPage05('block');

    }

    if (params == 6) {
      setPage01('none');
      setPage02('none');
      setPage03('none');
      setPage04('none');
      setPage05('none');
      setPage06('block');
    }
    if (params == 7) {
      setPage01('none');
      setPage02('none');
      setPage03('none');
      setPage04('none');
      setPage05('none');
      setPage06('none');
      setPage07('block');
    }

  }

  async function finishMaster(params) {
    // Cria o usuario no firebase
    await createUserWithEmailAndPassword(firebaseAuth, emaill, senha)

    var data55 = new Date();


    var expiracaoConta = data55.toJSON();



    var diaAdia = new Date();
    var diaAtual = diaAdia.toJSON();

    await setDoc(doc(db, 'clientes', `${emaill}`), {
      nome: nome,
      uf: uf,
      cidade: cidade,
      idade: idade,
      telefone: telefone,
      precoPorHora: precoHr,
      aceitaCredito: credito,
      aceitaPix: pix,
      aceitaDinheiro: dinheiro,
      ligacao: ligacao,
      whatsApp: whatsapp,
      videoChamada: videochamada,
      branca: branca,
      latina: latina,
      mulata: mulata,
      negra: negra,
      oriental: oriental,
      loira: loira,
      morena: morena,
      ruiva: ruiva,
      alta: alta,
      mignon: mignon,
      gordinha: gordinha,
      magra: magra,
      silicone: silicone,
      natural: natural,
      peluda: peluda,
      comLocal: comLocal,
      depilada: depilada,
      beijo: beijo,
      duplas: duplas,
      ejaculacaoCorpo: ejaculacaoCorpo,
      facial: facial,
      fantasias: fantasias,
      massagem: massagem,
      namoradinha: namoradinha,
      oralAteFinal: oralAteFinal,
      oralCamisinha: oralCamisinha,
      oralSemCamisinha: oralSemCamisinha,
      pse: pse,
      sexoAnal: sexoAnal,
      beijoNegro: beijoNegro,
      chuvaDourada: chuvaDourada,
      chuvaNegra: chuvaNegra,
      fetichismo: fetichismo,
      gargantaProfunda: gargantaProfunda,
      sadoDuro: sadoDuro,
      sadoSuave: sadoSuave,
      squirting: squirting,
      strapOn: strapOn,
      domicilio: domicilio,
      swing: swing,
      despedidas: despedidas,
      festasEventos: festasEventos,
      hotel: hotel,
      jantarRomantico: jantarRomantico,
      viagens: viagens,
      sobre: sobre,
      sobre: sobre,
      url01: url01,
      url02: url02,
      url03: url03,
      url04: url04,
      url05: '',
      url06: '',
      url07: '',
      url08: '',
      url09: '',
      videoUp: '',
      verificado: false,
      subidasSemana: 2,
      subida: 0,
      expiracaoHoras: diaAtual,
      expiracaoPlano: diaAtual,
      expiracaoDaConta: expiracaoConta,
      contaNova: false,
      renew: 0,
      tipoPlano: 'nenhum',
      extras: false,
      ativo: ativo,

    });


    setTimeout(() => {
      navigate('/');
    }, 1000);

  }






  async function enviarImagensVideo(params) {

    finishMaster();


  }





  const uploadFile = async (params) => {
    if (!params) return;


    const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto01'}`);

    uploadBytes(imageRef, params).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setUrl01(url);

      });
    });

  };


  const uploadFile2 = (params) => {
    if (!params) return;


    const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto02'}`);

    uploadBytes(imageRef, params).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setUrl02(url);
      });
    });

  };

  const handleIdadeChange = (e) => {
    const novaIdade = e.target.value;
    setIdade(novaIdade);

    if (novaIdade < 18) {
      setErroIdade('A idade deve ser maior ou igual a 18 anos');
    } else {
      setErroIdade('');
    }
  };


  const uploadFile3 = (params) => {
    if (!params) return;


    const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto03'}`);

    uploadBytes(imageRef, params).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setUrl03(url);
      });
    });

  };


  const uploadFile4 = (params) => {
    if (!params) return;


    const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto04'}`);

    uploadBytes(imageRef, params).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setUrl04(url);
      });
    });

  };

  const uploadFile5 = (params) => {
    if (!params) return;
    setVideoUpload(params);

    const imageRef = ref(storage, `clientesMidias/${emaill}/${params.name}`);

    uploadBytes(imageRef, params).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setUrl05(url);
      });
    });

  };

  const options = (params) => {
    return params.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ));
  };



  return (

    <>
      <Header />

      <div class="card card-1" style={{ marginTop: '-100px', display: page01 }}>
        <div class="card-heading"></div>
        <div class="card-body">
          <h2 class="title">Criar conta grátis</h2>

          <div class="input-group" style={{ borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}` }}>
            <input class="input--style-1" type="text" placeholder="Email" name="Email"
              onChange={(e) => setEmaill(e.target.value)}
            />
          </div>
          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" style={{ borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}` }}>
                <input class="input--style-1 js-datepicker" type="password" placeholder="Senha" name="Senha"
                  onChange={(e) => setSenha(e.target.value)}
                />
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>

          </div>



          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" style={{ borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}` }}>
                <input class="input--style-1 js-datepicker" type="password"
                  onChange={(e) => setSenhaCnf(e.target.value)}
                  placeholder="Confirmar senha" name="Confirmar senha" />
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>



              </div>

            </div>

          </div>
          <div class="row row-space col-4">



            <button class="btn button"
              style={{ minWidth: '200px' }}
              onClick={() => setMaster(1)}

            >Cadastrar-se</button>
            {
              msgs.length > 2 ?
                <a style={{ color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginTop: '15px', marginLeft: '0px', backgroundColor: '#999' }}>{msgs.replace('auth/', '')}</a>
                : ''
            }


          </div>






        </div>
      </div>








      <div class="card card-1" style={{ marginTop: '-100px', display: page02 }}>
        <div class="card-heading"></div>
        <div class="card-body">
          <h2 class="title">Dados Pessoais</h2>

          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" style={{ borderColor: `${msgs2.length > 2 ? 'red' : '#CCCCCC'}` }}>
                <input
                  onChange={(e) => setNome(e.target.value)}
                  class="input--style-1" type="text" placeholder="Nome" name="name" />
              </div>
            </div>
          </div>
         
          <div class="row row-space">
            <div class="col-2">
              <span>UF</span>
              <div class="input-group" style={{ borderColor: `${msgs3.length > 2 ? 'red' : '#CCCCCC'}` }}>
              <select 
                  class="form-control"
                  value={uf}
                  onChange={(e) => setUf(e.target.value)  } 
                  placeholder="UF"
              >
              <option disabled value="">
                  Selecione um estado
              </option>
                 {states.map((state) => (
                      <option key={state.sigla} value={state.sigla}>
                        {state.nome}
                      </option>
                    ))}
                </select>
              </div>
            </div>

             <div class="row row-space">
            <div class="col-2">
            <span>Cidade</span>
              <div class="input-group" style={{ borderColor: `${msgs3.length > 2 ? 'red' : '#CCCCCC'}` }}>
                <select
                    class="form-control"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    disabled={!municipalities.length} 
                  >
                    <option disabled value="">
                      Selecione uma cidade
                    </option>
                    {municipalities.map((municipio) => (
                      <option key={municipio.nome} value={municipio.nome}>
                        {municipio.nome}
                      </option>
                  ))}
                  </select>
              </div>
            </div>

          </div>

          </div>
          <div className="row row-space">
            <div className="col-2">
              <div className="input-group" style={{ borderColor: erroIdade ? 'red' : '#CCCCCC' }}>
                <input
                  onChange={handleIdadeChange}
                  className="input--style-1 js-datepicker"
                  type="number"
                  placeholder="Idade"
                  name="idade"
                  value={idade}
                />
                <i className="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
              {erroIdade && <p style={{ color: 'red', marginTop: '-10px' }}>{erroIdade}</p>}
            </div>
          </div>


          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" style={{ borderColor: `${msgs5.length > 2 ? 'red' : '#CCCCCC'}` }}>
                <input
                  onChange={(e) => setTelefone(e.target.value)}
                  class="input--style-1 js-datepicker" type="text" placeholder="Telefone" name="birthday" />
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>
          </div>




          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" style={{ borderColor: `${msgs6.length > 2 ? 'red' : '#CCCCCC'}` }}>
                <textarea
                  minLength="100"
                  maxLength="3000"
                  onChange={(e) => setSobre(e.target.value)}
                  class="input--style-1 input--textarea js-datepicker" type="text" placeholder="Apresentação/Bio" name="birthday" />
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>
          </div>








          <div class="row row-space col-4">



            <button class="btn button"
              style={{ minWidth: '200px' }}
              type="submit"
              onClick={() => setMaster(2)}
            >Continuar</button>

            {
              msgs2.length > 2 ?
                <a style={{ color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999' }}>{msgs2.replace('auth/', '')}</a>
                : ''
            }
            <br />
            {
              msgs3.length > 2 ?
                <a style={{ color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999' }}>{msgs3.replace('auth/', '')}</a>
                : ''
            }
            <br />
            {
              msgs4.length > 2 ?
                <a style={{ color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999' }}>{msgs4.replace('auth/', '')}</a>
                : ''
            }
            <br />
            {
              msgs5.length > 2 ?
                <a style={{ color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999' }}>{msgs5.replace('auth/', '')}</a>
                : ''
            }
            <br />
            {
              msgs6.length > 2 ?
                <a style={{ color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999' }}>{msgs6.replace('auth/', '')}</a>
                : ''
            }
            <br />
            {
              msgs7.length > 2 ?
                <a style={{ color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999' }}>{msgs6.replace('auth/', '')}</a>
                : ''
            }
          </div>
        </div>
      </div>





      <div class="card card-1" style={{ marginTop: '-100px', display: page03 }}>
        <div class="card-heading"></div>
        <div class="card-body">
          <h2 class="title">Preços / Contato</h2>

          <div class="input-group" style={{ width: '150px', display: 'flex', flexFlow: 'row nowrap' }}>
            <a style={{ marginTop: '9px', marginRight: '8px', color: '#666' }}>R$:  </a><input
              onChange={(e) => setPrecoHr(e.target.value)}
              class="input--style-1" type="text" placeholder="Preço por hora" name="preço por hora" />
          </div>
          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Pagamentos:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Cartão de crédito

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setCredito(e.target.checked)}
                    />
                  </div>



                </div>

                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Pix

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setPix(e.target.checked)}
                    />
                  </div>


                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Dinheiro

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setDinheiro(e.target.checked)}
                    />
                  </div>



                </div>

                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>

          </div>
          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Contato:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Ligação

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setLigacao(e.target.checked)}
                    />
                  </div>


                </div>

                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Whatsapp

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setWhatsapp(e.target.checked)}
                    />
                  </div>



                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Videochamada

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setVideochamada(e.target.checked)}
                    />
                  </div>


                </div>

                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>

          </div>













          <div class="row row-space col-4">



            <button class="btn button"
              style={{ minWidth: '200px' }}
              type="submit"
              onClick={() => setMaster(3)}
            >Continuar</button>



          </div>


        </div>
      </div>








      <div class="card card-1" style={{ marginTop: '-100px', display: page04 }}>
        <div class="card-heading"></div>
        <div class="card-body">
          <h2 class="title">Aparência</h2>


          <div class="row row-space">
            <div class="col-7">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Etnia:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Branca

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setBranca(e.target.checked)}
                    />
                  </div>


                </div>

                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Latina

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setLatina(e.target.checked)}
                    />
                  </div>



                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Mulata

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setMulata(e.target.checked)}
                    />
                  </div>



                </div>

                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Negra

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setNegra(e.target.checked)}
                    />
                  </div>


                </div>

                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oriental

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setOriental(e.target.checked)}
                    />
                  </div>



                </div>

                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>

          </div>



          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Cabelo:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Loira

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setLoira(e.target.checked)}
                    />
                  </div>


                </div>

                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Morena

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setMorena(e.target.checked)}
                    />
                  </div>

                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Ruiva


                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setRuiva(e.target.checked)}
                    />
                  </div>



                </div>

                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>

          </div>




          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Estatura:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Alta


                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setAlta(e.target.checked)}
                    />
                  </div>


                </div>

                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Mignon

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setMignon(e.target.checked)}
                    />
                  </div>


                </div>


                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>

          </div>
          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Corpo:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Gordinha


                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setGordinha(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Magra
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setMagra(e.target.checked)}
                    />
                  </div>
                </div>
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>

          </div>
          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Seios:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Silicone


                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setSilicone(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Natural

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setNatural(e.target.checked)}
                    />
                  </div>
                </div>
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>
          </div>
          <div class="row row-space">
            <div class="col-2">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Púbis:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Peludo

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setPeluda(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Depilado
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setDepilada(e.target.checked)}
                    />
                  </div>
                </div>
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>

          </div>
          <div class="row row-space col-4">
            <button class="btn button"
              style={{ minWidth: '200px' }}
              type="submit"
              onClick={() => setMaster(4)}
            >Continuar</button>
          </div>
        </div>
      </div>

      <div class="card card-1" style={{ marginTop: '-100px', display: page05 }}>
        <div class="card-heading"></div>
        <div class="card-body">
          <h2 class="title">Serviços</h2>
          <div class="row row-space">
            <div class="col-7">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Serviços gerais:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Beijos na boca

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setBeijo(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Duplas

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setDuplas(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Ejaculação corpo
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setEjaculacaoCorpo(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Facial
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setFacial(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Fantasias e disfarces
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setFantasias(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Massagem erótica

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setMassagem(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Namoradinha
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setNamoradinha(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral até o final
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setOralAteFinal(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral com camisinha
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setOralCamisinha(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral sem camisinha

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setOralSemCamisinha(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>PSE
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setPse(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sexo anal
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setSexoAnal(e.target.checked)}
                    />
                  </div>
                </div>
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>
          </div>
          <div class="row row-space">
            <div class="col-7">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Serviços especiais:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Beijo negro


                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setBeijoNegro(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Chuva dourada
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setChuvaDourada(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Chuva negra
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setChuvaNegra(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Fetichismo
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setFetichismo(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Garganta profunda
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setGargantaProfunda(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sado duro
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setSadoDuro(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sado suave
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setSadoSuave(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Squirting
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setSquirting(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Strap on
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setStrapOn(e.target.checked)}
                    />
                  </div>
                </div>
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>
          </div>
          <div class="row row-space">
            <div class="col-7">
              <div class="input-group" > <h5 style={{ marginTop: '12px', marginRight: '15px', color: '#555' }}>Lugar:</h5>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>A domicilio
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setDomicilio(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Clube de Swing
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setSwing(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Com local
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setComLocal(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Despedidas de solteiro
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setDespedidas(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Festas e eventos
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setFestasEventos(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Hotel

                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setHotel(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Jantar romântico
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setJantarRomantico(e.target.checked)}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Viagens
                  <div class="checkbox-wrapper-2" style={{ paddingTop: '5px', marginLeft: '10px' }}>
                    <input type="checkbox" class="sc-gJwTLC ikxBAC"
                      onChange={(e) => setViagens(e.target.checked)}
                    />
                  </div>
                </div>
                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
              </div>
            </div>
          </div>
          <div class="row row-space col-4">
            <button class="btn button"
              style={{ minWidth: '200px' }}
              type="submit"
              onClick={() => setMaster(6)}
            >Continuar</button>
          </div>
        </div>
      </div>
      <div class="card card-1" style={{ marginTop: '-100px', display: page06 }}>
        <div class="card-heading"></div>
        <div class="card-body">
          <h2 class="title">Fotos</h2>

          <div class="input-group" style={{ width: '400px' }} >
            <a>Foto 1 (Perfil)</a>  <input
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
                uploadFile(event.target.files[0]);
              }}
            />
          </div>

          {
            url01.length > 2 ?

              <div class="input-group" style={{ width: '400px' }} >
                <a>Foto 2</a>  <input
                  type="file"
                  onChange={(event) => {
                    uploadFile2(event.target.files[0]);
                  }}
                />
              </div>

              : ''
          }

          {
            url02.length > 2 ?
              <div class="input-group" style={{ width: '400px' }} >
                <a>Foto 3</a>  <input
                  type="file"
                  onChange={(event) => {
                    uploadFile3(event.target.files[0]);
                  }}
                />
              </div>

              : ''
          }
          {
            url03.length > 2 ?

              <div class="input-group" style={{ width: '400px' }} >
                <a>Foto 4</a>  <input
                  type="file"
                  onChange={(event) => {
                    uploadFile4(event.target.files[0]);
                  }}
                />
              </div>

              : ''
          }
          <div class="row row-space col-4">
            {url01.length > 2 ?
              <button class="btn button"
                style={{ minWidth: '200px' }}
                type="submit"
                onClick={() => finishMaster()}
              >Continuar</button>
              :
              <button disabled class="btn button"
                type="submit"
                style={{ minWidth: '200px' }}
              >Continuar</button>
            }
          </div>
        </div>
      </div>

      <div class="card card-1" style={{ marginTop: '-100px', display: page07 }}>
        <div class="card-heading"></div>
        <div class="card-body">
          <h2 class="title">Fotos</h2>

          <div class="input-group" style={{ width: '400px' }} >
            <a>Foto 1 (Perfil)</a>  <input
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
              }}
            />
          </div>

          <div class="input-group" style={{ width: '400px' }} >
            <a>Foto 2</a>  <input
              type="file"
              onChange={(event) => {
                setImageUpload2(event.target.files[0]);
              }}
            />
          </div>

          <div class="input-group" style={{ width: '400px' }} >
            <a>Foto 3</a>  <input
              type="file"
              onChange={(event) => {
                setImageUpload3(event.target.files[0]);
              }}
            />
          </div>

          <div class="input-group" style={{ width: '400px' }} >
            <a>Foto 4</a>  <input
              type="file"
              onChange={(event) => {
                setImageUpload4(event.target.files[0]); }}/>
          </div>
          <div class="row row-space col-4">
            <button class="btn button"
              type="submit"
              onClick={() => enviarImagensVideo()}
            >Finalizar Cadastro</button>
          </div>


        </div>
      </div>
    </>
  );
}

export default Register;







